import React from 'react';
import clsx from 'clsx';

export default function Logo({ className }) {
  return (
    <div className={clsx('flex items-center justify-center', className)}>
      <img 
        src="/images/hkm-logo.png"
        alt="Hong Kong Mall Logo"
        className="h-full w-full object-contain"
      />
    </div>
  );
}