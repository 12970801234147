import React from 'react';
import Logo from '../common/Logo';
import { spacing } from '../../constants/theme';

export default function Header() {
  return (
    <header className="bg-white shadow-md sticky top-0 z-50">
      <nav className={`${spacing.container} py-4`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div className="h-12 w-24">
              <Logo className="h-full w-full" />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}