export const colors = {
  primary: '#E6007E',
  secondary: '#333333',
  brand: {
    bonjour: '#FF4D4F',
    hkm: '#1890FF',
    global: '#52C41A'
  },
  background: {
    light: '#F9FAFB',
    dark: '#1F2937'
  },
  text: {
    primary: '#1F2937',
    secondary: '#6B7280',
    light: '#FFFFFF'
  }
};

export const spacing = {
  container: 'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'
};