import React from 'react';

export default function CompanyCard({ name, description, link, logo }) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="group block bg-white rounded-lg shadow-md hover:shadow-xl transition-all duration-300 p-6 h-full"
    >
      <div className="flex flex-col items-center text-center h-full">
        <div className="h-32 flex items-center justify-center mb-4">
          <img 
            src={logo}
            alt={`${name} logo`}
            className="max-h-full max-w-[160px] object-contain"
          />
        </div>
        <div className="flex-1 flex flex-col justify-between w-full">
          <h3 className="text-xl font-bold mb-3 text-gray-900">{name}</h3>
          <p className="text-gray-600 text-sm">{description}</p>
        </div>
      </div>
    </a>
  );
}