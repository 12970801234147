import React from 'react';
import Logo from '../common/Logo';
import { spacing } from '../../constants/theme';

export default function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-8 sticky bottom-0 w-full z-10">
      <div className={spacing.container}>
        <div className="flex flex-col items-center space-y-6">
          <div className="h-16 w-16 bg-white rounded-lg p-2" style={{ aspectRatio: '1 / 1' }}>
            <Logo className="h-full w-full" />
          </div>
          <div className="text-center space-y-4">
            <p>© {new Date().getFullYear()} 香港猫. All rights reserved.</p>
            <a
              href="http://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-gray-300 transition-colors text-sm block"
            >
              粤ICP备2020126184号-4
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}