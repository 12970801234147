import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header className="flex-shrink-0" />
      <main className="flex-auto">
        {children}
      </main>
      <Footer className="flex-shrink-0" />
    </div>
  );
}