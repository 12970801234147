import { colors } from './theme';

export const companies = [
  {
    name: '卓悅控股有限公司',
    description: '香港领先美妆零售集团，提供优质美妆产品和专业服务',
    link: 'https://corp.bonjourhk.com',
    logo: '/images/bonjour-group-logo.png'
  },
  {
    name: '香港猫',
    description: '专业跨境电商平台，连接全球优质商品',
    link: 'https://cn.hongkongmall.com',
    logo: '/images/hkm-logo.png'
  },
  {
    name: 'Bonjour Global',
    description: '国际化美妆购物平台，汇聚全球美妆精品',
    link: 'https://www.bonjourglobal.com/',
    logo: '/images/bonjour-global-logo.jpg'
  }
];