import React from 'react';
import CompanyCard from './home/CompanyCard';
import RedirectCountdown from './RedirectCountdown';
import { companies } from '../constants/companies';

export default function MainContent() {
  return (
    <div className="animated-background">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 flex-grow">
        <h1 className="text-3xl font-bold text-center mb-8">我们的品牌</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {companies.map((company) => (
            <CompanyCard key={company.name} {...company} />
          ))}
        </div>
        <div className="py-4">
          <RedirectCountdown />
        </div>
      </div>
    </div>
  );
}